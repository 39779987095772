
<template>
    <div>
        <div>Test</div>
        <button class="btn btn-secondary" @click="loadGoogleDrive()">Connect Google</button>
        <div id="googleFile"></div>
    </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'

export default {
    name: 'Test',
    components: { degrees, PDFDocument, rgb, StandardFonts },
    setup () {
        const axios = inject('axios')
        const isPickerApiLoaded = ref(false)
        const googleAccessToken = ref(null)

        const loadGoogleDrive = () => {
            console.info('loadGoogleDrive', 'googleAccessToken:', googleAccessToken.value, typeof googleAccessToken.value)

            if (!isPickerApiLoaded.value) {
                console.info('loadGoogleDrive', 'Google Drive is loading..')
            } 

            if (googleAccessToken.value === null) {
                console.info('loadGoogleDrive', 'OAuth token is missing.')
            }

            let picker = new google.picker.PickerBuilder()
                .addView(google.picker.ViewId.DOCS)
                .setOAuthToken(googleAccessToken.value)
                .setDeveloperKey('AIzaSyBWnsM_p90_H33TxcTNAQdXs7FUdLwiVIQ') // the Google API Key
                .setCallback(pickerCallback)
                .build()

            console.info('- - loadGoogleDrive - picker', picker)
            picker.setVisible(true)
        }

        const pickerCallback = (data) => {
            console.info('pickerCallback data', JSON.stringify(data))
            if (data.action === google.picker.Action.PICKED) {
                let file = data.docs[0]
                console.info('- - pickerCallback file', JSON.stringify(file))
                document.getElementById('googleFile').innerHTML = 'Selected File: ' + file.name +' (ID: ' + file.id + ')'
                // document.getElementById('googleFile').innerHTML = 'Selected File: ' + file.name

                // axios method
                uploadGoogleDoc(file.id, file.name)

            }
        }

        const loadGoogleApi = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script')
                script.src = 'https://apis.google.com/js/api.js'
                script.onload = () => resolve()
                script.onerror = (error) => reject(error)
                document.head.appendChild(script)
            })
        }

        const iniLoadGoogleDrive = () => {

            return new Promise((resolve, reject) => {
                gapi.load('picker', {
                    callback: () => resolve(),
                    onerror: (error) => reject(error)
                })
            })
        }

        onMounted(async () => {
            if (func.isEmptyObject(localStorage.getItem('googleToken')) === false) {
                let objLSGoogleToken = func.decodeStrToJsonSimple(localStorage.getItem('googleToken'))
                // console.info('objLSGoogleToken', objLSGoogleToken.access_token, JSON.stringify(objLSGoogleToken))
                googleAccessToken.value = objLSGoogleToken.access_token
            } 

            try {
                await loadGoogleApi()       // insert api script
                await iniLoadGoogleDrive()  // Initialize the Picker API
                isPickerApiLoaded.value = true

            } catch (error) {
                console.info('OnMounted', 'Error loading Google Picker API:', error)
            }

        })

        return { 
            isPickerApiLoaded, googleAccessToken, loadGoogleDrive
        }
    }
}
</script>

<style>

</style>